<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="cropped"></v-img>
        </v-avatar>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col md="4" cols="12">
              <v-text-field readonlys v-model="branch" label="Branch" dense outlined></v-text-field>
            </v-col>
            <v-col md="4" cols="12">
              <v-select
                v-model="kmf_no"
                dense
                outlined
                label="Kmf #"
                :items="kmf_no_items"
                item-value="kmf_series"
                item-text="kmf_series"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col md="4" cols="12">
              <v-text-field
                type="date"
                v-model="effective_date"
                label="Effective Date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card flat>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4> </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      @change="compute_age"
                      @keyup="compute_age"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field v-model="age" label="Age" dense outlined readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single', 'Married', 'Widowed', 'Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male', 'Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="occupation"
                      label="Occupation"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-text-field
                      v-model="address"
                      label="Address"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">SALES AGENT INFORMATION</h4> </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="payment"
                      dense
                      outlined
                      label="Amount"
                      :items="['150']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="sales_agent_id"
                      dense
                      outlined
                      label="Sales Agent"
                      :items="sales_agent_items"
                      item-value="id"
                      item-text="full_name"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_member" v-if="!saving"> Save changes </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color" :snackbar_text="this.snackbar_text" />
  </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    cropped: require('@/assets/images/avatars/2.png'),

    saving: false,
    alert: false,
    alert_message: '',

    callers_id: '',
    kmf_no: '',
    last_name: '',
    first_name: '',
    middle_name: '',
    date_of_birth: '',
    age: 0,
    gender: '',
    civil_status: '',
    religion: '',
    address: '',
    effective_date: '',
    occupation: '',

    payment: '',
    kmf_no_items: [],
    sales_agent_id: 0,
    sales_agent_items: [],
  }
}

export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      show: false,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiAccountSearchOutline,
      },
    }
  },
  created() {
    this.initialize_data()
  },
  data() {
    return initialState()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('kaagapay', ['save_new_kaagapay']),
    ...mapActions('series_kmf', ['get_available_kmf']),
    ...mapActions('staff', ['find_staff_with_position_active']),
    initialize_data() {
      const data = new FormData()
      data.append('branch_id', this.branch_id)
      this.get_available_kmf(data)
        .then(response => {
          this.kmf_no_items = response.data
        })
        .catch(error => {
          this.saving = false
        })
      const data3 = new FormData()
      data3.append('branch_id', this.branch_id)
      data3.append('positions', 'Sales Agent')
      this.find_staff_with_position_active(data3)
        .then(response => {
          this.sales_agent_items = response.data
        })
        .catch(error => {
          this.saving = false
        })
    },
    reset() {
      Object.assign(this.$data, initialState())
      this.initialize_data()
    },
    compute_age() {
      this.age = moment(this.effective_date, 'YYYY-MM-DD').diff(moment(this.date_of_birth, 'YYYY-MM-DD'), 'years')
    },
    save_member() {
      this.saving = true
      this.alert = false
      var flag = false
      if (
        moment(this.effective_date, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
          moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY') ||
        moment(this.effective_date, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
          moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')
      ) {
        flag = true
      } else {
        var compareDate = moment(this.effective_date, 'YYYY-MM-DD')
        var startDate = moment(this.month_start, 'MMMM D, YYYY')
        var endDate = moment(this.month_end, 'MMMM D, YYYY')

        flag = compareDate.isBetween(startDate, endDate) //false in this case
      }
      if (flag === false) {
        this.effective_date = ''
        this.alert = true
        this.alert_message = 'Date of Service is Not Belong in Transaction Month!'
        this.saving = false
      } else {
        if (this.$refs.form.validate()) {
          var index = this.kmf_no_items
            .map(function (x) {
              return x.kmf_series
            })
            .indexOf(this.kmf_no)
          var transmital_kmf_id = this.kmf_no_items[index].transmital_kmf_id
          const data = new FormData()
          data.append('branch_id', this.branch_id)
          data.append('sales_agent_id', this.sales_agent_id)
          data.append('kmf_no', this.kmf_no)
          data.append('transmital_kmf_id', transmital_kmf_id)
          data.append('last_name', this.last_name.toUpperCase())
          data.append('first_name', this.first_name.toUpperCase())
          data.append('middle_name', this.middle_name.toUpperCase())
          data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'))
          data.append('age', this.age)
          data.append('gender', this.gender)
          data.append('civil_status', this.civil_status)
          data.append('religion', this.religion)
          data.append('occupation', this.occupation)
          data.append('address', this.address)
          data.append('payment', this.payment)
          data.append('effective_date', moment(this.effective_date, 'YYYY-MM-DD').format('MMMM D, YYYY'))
          data.append('month_of', this.month_of)
          data.append('user_id', this.user_id)
          this.save_new_kaagapay(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color:color,
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  },
}
</script>
